import { useLocalStorage } from '@mantine/hooks';
import dayjs from 'dayjs';
import { isEmpty, isNil, takeRight } from 'lodash';

export enum RecentlyViewedType {
  policy = 'POLICY',
  quote = 'QUOTE',
  forms = 'FORMS',
  other = 'OTHER',
}
export type RecentlyViewedData = {
  url: string;
  label: string;
  timestamp: string;
  type?: RecentlyViewedType;
};

const RECENTLY_VIEWED = 'recently-viewed';

export const useUpdateRecentlyViewed = (input: Omit<RecentlyViewedData, 'timestamp'> | null) => {
  if (isNil(input)) {
    return;
  }

  const stored = localStorage.getItem(RECENTLY_VIEWED);

  const prev: Array<RecentlyViewedData> = isNil(stored) ? [] : JSON.parse(stored);
  const data = { ...input, timestamp: dayjs().toISOString() };
  if (isEmpty(prev)) {
    localStorage.setItem(RECENTLY_VIEWED, JSON.stringify([data]));
  }
  //prevent duplicates
  const newRecentlyViewed = prev.filter((rv) => rv.label !== data.label);

  //add new data and take 5 most recent
  newRecentlyViewed.push(data);
  const fiveRecent = takeRight(newRecentlyViewed, 5);
  localStorage.setItem(RECENTLY_VIEWED, JSON.stringify(fiveRecent));
};

export const useRecentlyViewed = () => {
  const [recent] = useLocalStorage<Array<RecentlyViewedData>>({
    key: RECENTLY_VIEWED,
    defaultValue: [],
  });

  return Array.from(recent || []).reverse();
};
