import { Button, SimpleGrid, Title, Anchor } from '@mantine/core';
import Link from 'next/link';
import { ArrowForward } from '@emotion-icons/ionicons-outline/ArrowForward';
import React from 'react';
import MainAppShell from '../../components/Layout/MainAppShell';
import RecentlyViewed from '../../components/RecentlyViewed';

const RiskModelsHome = () => {
  return (
    <MainAppShell adminOnly>
      <SimpleGrid cols={1}>
        {/* <div>
          <Title>Welcome to Shepherd</Title>
          <h3>Grind & Bind 👊</h3>
        </div> */}
        <SimpleGrid cols={1} mt="md">
          <Title order={6}>Platform Links</Title>
          <Link legacyBehavior href="/u/policies" passHref>
            <Button
              component="a"
              rightSection={<ArrowForward width={24} />}
              variant="default"
              size="sm"
              fullWidth
              styles={{
                inner: {
                  justifyContent: 'space-between',
                },
              }}
            >
              Policies
            </Button>
          </Link>
          <Link legacyBehavior href="/u/policyholders" passHref>
            <Button
              component="a"
              rightSection={<ArrowForward width={24} />}
              variant="default"
              size="sm"
              fullWidth
              styles={{
                inner: {
                  justifyContent: 'space-between',
                },
              }}
            >
              Policyholders
            </Button>
          </Link>
          <Link legacyBehavior href="/u/brokerages" passHref>
            <Button
              component="a"
              rightSection={<ArrowForward width={24} />}
              variant="default"
              size="sm"
              fullWidth
              styles={{
                inner: {
                  justifyContent: 'space-between',
                },
              }}
            >
              Brokerages
            </Button>
          </Link>
          <Link legacyBehavior href="/u/carriers" passHref>
            <Button
              component="a"
              rightSection={<ArrowForward width={24} />}
              variant="default"
              size="sm"
              fullWidth
              styles={{
                inner: {
                  justifyContent: 'space-between',
                },
              }}
            >
              Carriers
            </Button>
          </Link>
          <Anchor
            href="https://docs.google.com/spreadsheets/d/10FV1ZubRNO15NRHv14ETKTCK2z7G_MT-Y7zqv5S2Qzc/edit#gid=0"
            target="_blank"
          >
            <Button
              component="a"
              rightSection={<ArrowForward width={24} />}
              variant="default"
              size="sm"
              fullWidth
              styles={{
                inner: {
                  justifyContent: 'space-between',
                },
              }}
            >
              Licenses
            </Button>
          </Anchor>
        </SimpleGrid>
        <Title order={6}>Productivity</Title>
        <SimpleGrid cols={1}>
          <Link legacyBehavior href="/u/form-management" passHref>
            <Button
              component="a"
              rightSection={<ArrowForward width={24} />}
              variant="default"
              size="sm"
              fullWidth
              styles={{
                inner: {
                  justifyContent: 'space-between',
                },
              }}
            >
              Tully's Forms Paradise
            </Button>
          </Link>
          <Link legacyBehavior href="/u/platform-defaults" passHref>
            <Button
              component="a"
              rightSection={<ArrowForward width={24} />}
              variant="default"
              size="sm"
              fullWidth
              styles={{
                inner: {
                  justifyContent: 'space-between',
                },
              }}
            >
              Platform Defaults
            </Button>
          </Link>
        </SimpleGrid>
        <RecentlyViewed />
      </SimpleGrid>
    </MainAppShell>
  );
};

export default RiskModelsHome;
